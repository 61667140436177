import { color } from "@styles";
import { FC, ReactElement } from "react";
import styled from "styled-components";
import { Tooltip } from "./Tooltip";

interface SingleStatProps {
  stat: Stat;
  inverted?: boolean;
}

interface Stat {
  value: string;
  description: string;
  tooltip?: string | ReactElement;
  tooltipDesktopWidth?: string;
  marginLeft?: string;
  marginRight?: string;
  bold?: boolean;
  maxWidth?: string;
}

export const SingleStat: FC<SingleStatProps> = ({
  stat: { value, description, tooltip, tooltipDesktopWidth, marginLeft, marginRight, bold = false, maxWidth },
  inverted = false,
}) => (
  <StatContainer>
    <Stat inverted={inverted}>{value}</Stat>
    <Description
      marginLeft={marginLeft}
      marginRight={marginRight}
      inverted={inverted}
      hasTooltip={!!tooltip}
      bold={bold}
      maxWidth={maxWidth}
    >
      <span>
        {description}
        {tooltip && (
          <ToolTipWrap>
            <Tooltip tooltipDesktopWidth={tooltipDesktopWidth} inverted={inverted}>
              {tooltip}
            </Tooltip>
          </ToolTipWrap>
        )}
      </span>
    </Description>
  </StatContainer>
);

const TIGHT_MOBILE_BREAKPOINT = `@media only screen and (max-width: 27.5rem)`;
const COLLAPSE_BREAKPOINT = `@media only screen and (max-width: 65rem)`;

export const StatContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.5rem;

  ${COLLAPSE_BREAKPOINT} {
    padding-top: 2.5rem;
  }

  ${TIGHT_MOBILE_BREAKPOINT} {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: auto;
    width: 12.5rem;
    gap: 1.5rem;
    padding-top: 2.5rem;
  }
`;

export const Stat = styled.p<{ inverted: boolean }>`
  color: white;
  color: ${(p) => (p.inverted ? color.P100 : color.white)};
  font-size: 3.125rem;
  font-weight: 700;
  text-align: center;
`;

export const Description = styled.div<{
  hasTooltip?: boolean;
  inverted: boolean;
  marginLeft?: string;
  marginRight?: string;
  bold?: boolean;
  maxWidth?: string;
}>`
  color: white;
  color: ${(p) => (p.inverted ? color.B1100 : color.white)};
  font-size: 1rem;
  font-weight: ${(p) => (p.inverted && !p.bold ? 400 : 700)};
  letter-spacing: 0.075rem;
  text-align: left;
  margin-left: ${(p) => (p.marginLeft ? p.marginLeft : "1.5rem")};
  margin-right: ${(p) => p.marginRight};
  max-width: ${(p) => (p.maxWidth ? p.maxWidth : "9.375rem")};

  ${COLLAPSE_BREAKPOINT} {
    margin: 0 0 0 1.5rem;
  }

  ${TIGHT_MOBILE_BREAKPOINT} {
    margin: 0;
    text-align: center;
  }
`;

const ToolTipWrap = styled.span`
  position: relative;
`;
