import { tablet } from "components/templates/Breakpoints";
import { CSSProperties, FC, PropsWithChildren, useState } from "react";
import styled from "styled-components";
import { color, fonts } from "../../styles";

interface Props {
  tooltipDesktopWidth?: string;
  tooltipWhite?: boolean;
  inverted?: boolean;
  isOnHeading?: boolean;
  iconPositioning?: CSSProperties;
  infoBoxPositioning?: CSSProperties;
  tipPositioning?: CSSProperties;
}

export const Tooltip: FC<PropsWithChildren<Props>> = ({
  children,
  tooltipDesktopWidth = "18.75rem",
  inverted = false,
  isOnHeading = false,
  iconPositioning,
  infoBoxPositioning,
  tipPositioning,
}) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <ToolTipWrap
      isOnHeading={isOnHeading}
      onMouseEnter={() => {
        setIsActive(true);
      }}
      onMouseLeave={() => {
        setIsActive(false);
      }}
    >
      <img
        src={`/static/icons/${inverted ? "TooltipGrey" : "TooltipWhite"}.svg`}
        alt="Information Icon"
        style={{ position: "absolute", left: "0.4rem", top: "", ...iconPositioning }}
      />

      {isActive && (
        <>
          <InfoBox
            inverted={inverted}
            // In-line styling is more performant than using styled components, in this case
            style={{
              left: `calc((-${tooltipDesktopWidth} / 2) + (${ICON_WIDTH} / 2) + 0.4rem)`,
              width: tooltipDesktopWidth || TOOLTIP_WIDTH,
              position: "absolute",
              bottom: "0.6rem",
              ...infoBoxPositioning,
            }}
            $tipPositioning={tipPositioning}
          >
            <Text inverted={inverted}>{children}</Text>
          </InfoBox>
          <HoverZone />
        </>
      )}
    </ToolTipWrap>
  );
};

const TOOLTIP_WIDTH = "18.75rem";
const ICON_WIDTH = "21px";
const HOVER_ZONE_WIDTH = "3rem";

const ToolTipWrap = styled.span<{ isOnHeading?: boolean }>`
  top: ${({ isOnHeading }) => (isOnHeading ? `0.3rem` : `-0.1rem`)};
  position: absolute;
`;

const HoverZone = styled.div`
  position: absolute;
  width: ${HOVER_ZONE_WIDTH};
  height: ${HOVER_ZONE_WIDTH};
  right: calc((-${HOVER_ZONE_WIDTH} / 2) + (${ICON_WIDTH} / 2));
  bottom: 21px;
`;

const InfoBox = styled.div<{
  inverted?: boolean;
  isRewards?: boolean;
  $tipPositioning?: CSSProperties;
}>`
  background-color: ${(p) => (p.inverted ? color.S3100 : "white")};
  ${(p) => (p.inverted ? "color: white" : "")};
  margin: 0;
  border-radius: 0.5rem;
  z-index: 9;

  ::after {
    content: "";
    position: absolute;
    top: ${(p) => p.$tipPositioning?.top || "100%"};
    left: ${(p) => p.$tipPositioning?.left || "50%"};
    bottom: ${(p) => p.$tipPositioning?.bottom};
    right: ${(p) => p.$tipPositioning?.right};
    transform: ${(p) => p.$tipPositioning?.transform};
    margin-left: ${(p) => p.$tipPositioning?.marginLeft || "-0.3125rem"};
    border-width: ${(p) => p.$tipPositioning?.borderWidth || "0.3125rem"};
    border-style: solid;
    border-color: ${(p) => `${p.inverted ? color.S3100 : "white"} transparent transparent`};
  }

  ${tablet} {
    right: calc(-${ICON_WIDTH} / 2);
    width: ${TOOLTIP_WIDTH};
  }
`;

const Text = styled.div<{ inverted?: boolean }>`
  font-size: 1rem;
  line-height: 1.5rem;
  letter-spacing: 0.075rem;
  text-align: center;
  padding: 1.25rem;
  font-family: ${fonts.body};
  font-weight: normal;
  color: ${(p) => (p.inverted ? color.white : color.B1100)};

  p {
    color: ${color.white};
  }

  a {
    color: ${color.white};
    text-decoration: underline;
  }
`;
